body {
    background: white !important;
    line-height: 1.42857143;
}
.leftmargin {
    margin-left: 100px;
}
.invoice-items-list-table .thead-light th {
    padding: 8px;
}
.invoice-items-list-table td,
.invoice-items-list-table th {
    font-size: 14px;
    padding: 8px !important;
}
.page-main-content {
    color: #333;
}
.block-center {
    margin: auto;
    display: block;
}
.block-center img {
    margin: auto;
    display: block;
}
.leftmargin {
    margin-left: 100px;
}
.grey {
    color: gray;
}
@media print {
    @page {
        size: auto;
        margin: 0;
    }
    span.font-weight-900 {
        color: #000;
    }
    .invoice-title {
        color: #000;
    }
  }