$cAccent: #3978EF;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

input.form-control,
input.form-control:focus {
  color: #333;
}
.add-receipt-table .input-shipping-check {
  padding: 5px !important;
}
.date-column-last:last-child td {
  border-bottom-color: #e9ecef !important
}
.check-table tbody .col-tm-content {
  padding: 0 !important;
}
.check-table tbody .col-tm-content .row {
  margin-right: -1px !important;
}
.date-stat-page .Collapsible__trigger {
  text-align: center;
}

#navbar-main {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important; }

.db-sized-checkbox {
  -ms-transform: scale(1.4); /* IE */
  -moz-transform: scale(1.4); /* FF */
  -webkit-transform: scale(1.4); /* Safari and Chrome */
  -o-transform: scale(1.4); /* Opera */
  transform: scale(1.4);
  padding: 10px;
}
.locks-table thead th {
  vertical-align: middle;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.checks-main-content .form-control-label {
  color: #333 !important;
  font-weight: 500;
}
.check-table tbody th,
.check-table tbody td,
.wrap-table tbody th,
.wrap-table tbody td {
  white-space: -o-pre-wrap; 
  word-wrap: break-word;
  white-space: pre-wrap; 
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
}
.checks-main-content.card-body {
  color: #333;
  padding: 10px !important;
}
.check-total-amount {
  font-weight: bold;
}
.custom-btn-info {
  padding: 6px 12px !important;
  background: #30b5dc !important;
  border-color: #2b89a5 !important;
}
.check-table tbody td {
  font-size: 14px !important;
  text-align: left !important;
  padding: 7px !important;
}
.table thead th {
  font-size: 14px !important;
  color: #333 !important;
  background: white !important;
  font-weight: bold;
  text-transform: none;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

@media (min-width: 1200px) {
  .page-main-content {
    width: 1170px;
    margin: auto;
  }
  table.metro-add-item {
    width: 85%;
  }
  .page-date-main-content {
    width: 1080px;
    margin: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page-main-content {
    width: 970px;
    margin: auto;
  }
  table.metro-add-item {
    width: 95%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .page-main-content {
    width: 750px;
    margin: auto;
  }
}
/**
* BEGIN ACCORDION
*/

.Collapsible {
  background-color: white;
  border-bottom: 2px solid white;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #69a2c3;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  padding: 10px;
  background: #69a2c3;
  font-size: 18px;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'Font Awesome\ 5 Free';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; }
.accordion-list{
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  max-width: 30rem;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow:
    0 0 0.8rem 0.1rem rgba(darken($cAccent, 20%), 0.06),
    0 20px 30px -10px rgba(darken($cAccent, 20%), 0.2);
  
  &__item{
    &+&{
      border-top: 1px solid $cLightGray;
    }
  }
}

.accordion-item{
  $self: &;

  &--opened{    
    #{ $self }__icon{
      transform: rotate(180deg);
    }
        
    #{ $self }__inner{
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }
    
    #{ $self }__content{
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__line{
    display: block;
    padding: 0.8rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 2;
    position: relative;
  }
  
  &__title{
    font-size: 1.6rem;
    margin: 0;
    font-weight: 700;
    color: $cBlack;
  }
  
  &__icon{
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
    opacity: 0.6;
  }
  
  &__inner{
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }
  
  &__content{
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 1.2rem 1.2rem;
  }
  
  &__paragraph{
    margin: 0;
    font-size: 1rem;
    color: $cGray;
    font-weight: 300;
    line-height: 1.3;
  }
}
.receipt-payment-table tr td {
  padding: 10px 0px;
  vertical-align: middle;
}
.receipt-payment-table .input-td input {
  height: 30px;
  font-size: 12px;
  padding: 2px 0.5rem !important;
  color: #696969;
}
/**
* --- END
*/
.mt--9 {
  margin-top: -7.6rem !important;
}
.mt--10 {
  margin-top: -9.6rem !important;
}
.pt-57 {
  padding-top: 57px !important;
}
.font-size-19 {
  font-size: 19px;
}
.card-body {
  /*padding: 1rem 0.5rem !important;*/
  padding: 1rem !important;
}
.invoice-title {
  color: lightgray;
  margin-top: 10px;
  font-size: 45px;
}
.footer {
  padding: 1.5rem !important;
}
.box-shadow-none {
  box-shadow: none;
}
.border-none {
  border: none !important;
}
.border-top-none {
  border-top: none !important;
}
.nav-tabs-c {
  padding-bottom: 2px;
}
.nav-tabs-c .nav-item {
  cursor: pointer;
}
.nav-tabs-c .nav-item a {
  border: 1px solid #eee;
  border-bottom: none;
}

table .datetime-col {
  width: 180px !important;
}

.invoice-delivery-date-label {
  text-align: right;
}



body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px !important;
}

.activeTools {
  background-color: #ddf1ff !important;
}
.header-links {
  a {
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid;
    color: #5e72e4;
    background-color: lightcyan;
    border-radius: 4px;
    margin-right: 5px;
  }
  a:hover {
    color: #3c54dc;
    background-color: cyan !important;
  }
}

@media (max-width: 480px) {
  .header-links {
    a {
      padding: 3px 4px;
      font-size: 10px;
    }
  }
}

.main-content .container-fluid {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.table-listing {
  th {
    padding-left: 3px !important;
    padding-right: 3px !important;
    text-align: center;
  }
  td {
    padding-left: 3px !important;
    padding-right: 3px !important;
    text-align: center;
  }
}
.pointer {
  cursor: pointer;
}
.card-height {
  min-height: 70vh;
}
.main-content {
  min-height: 70vh;
}
.profile-contact .react-tel-input input {
  width: 100% !important;
  height: 46px !important;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  border: 0 !important;
  transition: box-shadow 0.15s ease !important;

  &:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  }
}
.create-user-contact .react-tel-input input {
  width: 100% !important;
  height: 36px !important;
}
.loaderSpinner {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.6);
  opacity: 1;
}
.loaderSpinner img {
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.navbar-horizontal .navbar-brand img {
  height: 70px;
}

.filter {
  font-size: 13px;
  .filterBy {
    height: 35px !important;
    width: 120px !important;
  }
  .rw-select > * {
    width: 1-5em !important;
  }
}

/*---------------Pagination common-----------------*/
.pagination {
  margin: 25px auto;
  display: flex;
  justify-content: center;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #5e72e4;
  border-color: #5e72e4;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #b8d3d6;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #5e72e4;
  border-color: #5e72e4;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
// .pagination > li > a {
//   border: 1px solid black;
// color: white!important;
// }
/*--------------------------------------------------------*/

/*---------------Table common-----------------*/
table {
  background-color: white !important;
}
/*--------------------------------------------------------*/

@media (max-width: 576px) {
  .card-header h1 {
    font-size: 14px !important;
  }
  .loaderSpinner img {
    width: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .db-sized-checkbox {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
  }
}

/*-----------------------------------Tickets And Coins Listing --------------------------------------*/
.search input {
  height: 37px;
  width: 200px;
}
.search .search-drop {
  width: 140px;
}
.no-item {
  background-color: #ccc !important;
}
.no-sign {
  background-color: orangered !important;
}
.void-true {
  background-color: #848484 !important;
}
@-webkit-keyframes blinker {
  from {opacity: 1.0;}
  to {opacity: 0.0;}
}
.blink{
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:ease-in-out;
  -webkit-animation-direction: alternate;
}
.view-attachment-icon:hover {
  color: red;
  cursor: pointer;
}
.fileuploader-action.fileuploader-action-download-popup {
  color: #fff;
  background: #08c;
  border-radius: 50%;
  box-shadow: -1px 1px 6px #08c;
  width: 25px;
  height: 25px;
  padding-top: 3px;
}
.add-receipt-table .fileuploader-items {
  max-width: 70% !important;
}
.color-info {
  width: 15px;
  height: 15px;
}
.ticket-table {
  min-height: 300px;
  th {
    white-space: normal;
    padding: 8px !important;
    font-size: 0.70rem !important;
    text-align: left;
  }
  td {
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    padding: 8px !important;
    font-size: 14px;
    text-align: left;
  }

  input[type="number"] {
    padding: 10px;
    height: 30px;
    min-width: 60px;
    font-size: 12px;
    border-radius: 5px;
  }
  input[type="text"] {
    padding: 10px;
    height: 30px;
    min-width: 80px;
    font-size: 12px;
    border-radius: 5px;
  }
  .soldTo-drop {
    height: 30px;

    min-width: 80px !important;
    .rw-select > * {
      width: 1em;
    }
    text-align: left;
  }
  .rw-popup ul {
    text-align: left;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.custom-control {
  padding-left: 2.25rem;
}

/*_________________________________________________________________*/

/*--------------------------Add tickets CSS---------------------------------------*/
.copies {
  input {
    width: 100px !important;
  }
  border: 1px !important;
}
.add-ticket-table td,
.add-ticket-table th {
  vertical-align: middle !important;
  padding: 2px !important;
  font-size: 12px;
  border: 1px solid #808080;
}
.add-ticket-table input {
  height: 35px;
  font-size: 12px;
  padding: 2px 0.5rem !important;
  width: 80%;
  color: #696969;
}
.add-ticket-table input:focus {
  color: black;
  border: 2px solid;
  border-color: #80bdff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
}
.add-ticket-table th {
  width: 200px;
}
.width-middle {
  min-width: 350px;
}
.width-last {
  min-width: 150px;
  font-size: 10px !important;
}
.back-grey {
  background-color: #ccc;
}
.input-null {
  background-color: #e1a59f;
  color: white;
}
.input-null:focus {
  color: white;
  background-color: #e1a59f;
}
.input-null::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.5; /* Firefox */
}
.back-red {
  background-color: red;
}
.back-green {
  th {
    color: white;
  }
  background-color: green;
}
.bg-grn {
  background-color: green !important;
}
.back-orange {
  background-color: orange;
}

.copy-drop {
  font-size: 11px;
  button {
    padding: 3px 10px;
    font-size: 11px;
  }
  .race-drop {
    width: 80px !important;
    .rw-popup-container {
      width: 90px !important;
    }
  }
}
.payment-drop {
  width: 80px !important;
}
.from-drop {
  width: 250px !important;
}
.station-drop {
  width: 110px !important;
}
.booth-drop {
  width: 170px !important;
}
.race-drop {
  width: 150px !important;
  .rw-popup-container {
    width: 150px !important;
  }
}
.item-drop {
  min-width: 100% !important;
  .rw-popup-container {
    min-width: 100% !important;
  }
}
.add-ticket-table .rw-select > * {
  width: 1em;
}

.autocomplete-drop {
  width: 80% !important;
  .rw-popup-container {
    width: 80% !important;
  }
}
.autocomplete {
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 300px;
    border-radius: 4px;
    height: 30px !important;
  }

  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    position: absolute;
    border: 1px solid #999;
    // border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    z-index: 9999;
  }

  .suggestions li {
    padding: 0.5rem;
    background-color: white;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: #008f68 !important;
    // color: #fae042;
    cursor: pointer;
    font-weight: 700;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 0.4; /* Firefox */
  }
  // width: 80% !important;
}
.rw-filter-input input {
  width: 100%;
  border: 0;
  height: 22px;
  box-shadow: none;
}

.rw-filter-input input:focus {
  border: 0;
  box-shadow: none;
}

.add-item-btn {
  font-size: 11px;
  padding: 4px 12px;
}
.desc-table {
  width: 100%;

  td {
    padding: 2px 2px !important;
    vertical-align: middle;
    border: 0 !important;
    font-size: 12px !important;
    font-weight: 600;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input {
      padding-left: 6px !important;
      padding-right: 2px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      height: 29px;
      width: 100%;
    }
  }
}
.input-sm {
  padding-left: 6px !important;
  padding-right: 2px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  height: 29px;
  width: 100%;
}
.delete-item {
  color: #5e72e4 !important;
  cursor: pointer !important;
}
.delete-item:hover {
  color: #233dd2 !important;
}
@media (min-width: 1200px) {
  .desc-table {
    width: 80%;
  }
}

/*_________________________________________________________________*/

//------------------------------Full drop CSS-----------------------------
.full-height-drop .rw-list {
  max-height: 600px !important;
}

/*_________________________________________________________________*/

//------------------------------SideBar CSS-----------------------------

// @media (min-width: 200px) {
.side-links {
  position: absolute;
  top: 70px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
}

.navbar-vertical.navbar .navbar-brand-img {
  max-height: 2.5rem !important;
  text-align: center;
}

#wrapper {
  padding-left: 0;
}
#sidebar-wrapper {
  overflow: hidden;
  width: 0;
  padding: 0;
}
#wrapper.toggled #sidebar-wrapper {
  padding: 1rem 1rem;
}
#page-content-wrapper {
  position: relative;
  margin-left: 0;
}
#wrapper.toggled #page-content-wrapper {
  position: relative;
  margin-right: 0;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  width: 0;
  height: 100%;
  margin-right: -250px;
  overflow-y: auto;
  background: #000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

.navbar-vertical .navbar-nav .nav-link.active {
  background-color: #95cbf3;
}

#wrapper.toggled #page-content-wrapper {
  margin-right: 0px;
  margin-left: 0px;
}
// }

@media (max-width: 767.98px) {
  .quote-form-table .contact-method-options {
    text-align: center;
  }
  .invoice-delivery-date-label {
    text-align: left !important;
  }
  .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  .navbar-vertical .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9) !important;
  }
  .navbar-nav .nav-link span {
    color: rgba(255, 255, 255, 0.95) !important;
  }
}

@media (max-width: 577px) {
  .logo-side {
    display: none;
  }
}

//--------------------Search Container-----------------
.search-container {
  button {
    padding: 8px 21px !important;
  }
  input {
    font-size: 13px !important;
    height: 38px !important;
    border-radius: 5px !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  }
  .filter-By {
    font-size: 13px !important;
    height: 38px !important;
    border-radius: 5px !important;
    width: 110px !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    .rw-select > * {
      width: 1em !important;
    }
    .rw-select button {
      padding-left: 5px !important;
      padding-right: 10px !important;
    }
  }
  .rw-popup-container {
    font-size: 13px !important;
  }
}

@media (max-width: 768px) {
  .search-left-btn {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .quote-form-table .rw-dropdown-list {
    max-width: 30%;
  }
}
/*_________________________________________________________________*/

.action-menu {
  min-width: 10rem !important;
  button {
    font-size: 12px !important;
  }
}

.table-worksheet td {
  font-size:18px !important;
  text-align: left;
  padding: 8px;
  padding-left: 12px !important;
}

.sigPad {
  border: 1px solid black;
}

.add-receipt-table {
  td {
    vertical-align: middle !important;
    padding: 2px !important;
    font-size: 12px;
    border: 1px solid #808080;
  }
  th {
    text-align: center;
    vertical-align: middle !important;
    padding: 2px 15px !important;
    font-size: 12px;
    border: 1px solid #808080;
  }

  .input-td input {
    height: 30px;
    font-size: 12px;
    padding: 2px 0.5rem !important;
    color: #696969;
  }
  .input-td input:focus {
    color: black;
    border: 2px solid;
    border-color: #80bdff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
      0 0 8px rgba(126, 239, 104, 0.6);
    outline: 0 none;
  }
}

.transaction-page .d-flex .rw-widget,
.d-flex-full .rw-widget  {
  width: 100%;
}
.rw-multiselect-tag {
  margin-top: 0 !important;
  margin-bottom: 6px;
}

.items-list-table {
  tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
}

.high-value-ticket-table td {
  font-size: 11px !important;
}
.high-value-ticket-table th {
  font-size: 10px !important;
}
.high-value-ticket-table td,
.high-value-ticket-table th,
.quote-form-table td,
.quote-form-table th {
  padding: 8px  !important;
}
.quote-form-table .booth-drop {
  max-width: 50%;
}
.quote-form-table td:first-child {
  vertical-align: middle;
  white-space: normal;
  font-size: 14px;
}
.quote-form-table input[type="text"] {
  width: 60%;
  height: 35px;
}
.quote-form-table .row input[type="text"] {
  width: 100%;
}
.quote-form-table .width-input-full input[type="text"] {
  width: 100%;
}
#daily-count-page {
  .form-control {
    font-size: 0.875rem;
    width: 50%;
    height: calc(2rem + 2px);
  }
}

.Collapsible__trigger:after {
  cursor: pointer;
}

@media (max-width: 991.98px) {
  #daily-count-page {
    .form-control {
      width: 100% !important;
    }
  }
  .quote-form-table input[type="text"] {
    width: 100%;
  }
  .add-ticket-table input[name="paymentCheckAmount"],
  .add-ticket-table input[name="checkNumber"] {
    min-width: 20%;
  }
  .page-view-invoice .leftmargin {
    margin-left: 0;
  }
  .font-size-19 {
    margin-right: 0 !important;
    float: none !important;
  }
}

.invoice-items-list-table {
  td {
    padding: 10px !important;
  }
  tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
}

.add-receipt-item-table {
  .qty-col {
    width: 100px !important;
  }

  .unit-col {
    width: 90px !important;
  }
  .amount-col {
    width: 90px !important;
  }
  .action-col {
    width: 80px !important;
  }
  td {
    padding: 5px !important;
  }
  .input-td input {
    height: 30px;
    font-size: 12px;
    padding: 2px 0.5rem !important;
    color: #696969;
  }
  .input-td input:focus {
    color: black;
    border: 2px solid;
    border-color: #80bdff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
      0 0 8px rgba(126, 239, 104, 0.6);
    outline: 0 none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.icon-cross {
  padding: 0.1rem 0.35rem !important;
}
.non-selector {
  user-select: none;
}

.rw-list {
  max-height: none !important;
  font-size: 12px !important;
}

.display-order {
  button {
    font-size: 12px;
  }
}
//-----------------------metro items listing---------------------------
.metro-items-listing {
  th {
    white-space: normal;
    font-size: 10px !important;
    text-align: center;
    padding: 3px 5px !important;
  }
  td {
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    padding: 15px 5px !important;
    font-size: 11px;
    .add-btn {
      font-size: 11px;
      padding: 5px 10px;
    }
  }
  .metro-item-drop {
    font-size: 10px !important;
    text-align: left !important;
    // width: 70px !important;
    // .rw-popup-container {

    // width: 70px !important;
    // }
    .rw-list-option {
      font-size: 10px !important;
    }
  }
  .metro-type-drop {
    text-align: left !important;
    font-size: 10px !important;

    width: 70px !important;
    .rw-popup-container {
      width: 70px !important;
    }
    .rw-list-option {
      font-size: 10px !important;
    }
  }
  input {
    height: 25px !important;
    font-size: 11px;
    border-radius: 4px;
    padding: 5px 2px !important;
  }
}
.metro-items-listing .rw-list {
  font-size: 10px !important;
}
.metro-items-listing .rw-input {
  padding-left: 1px !important;
}
.metro-add-item .rw-input {
  padding-left: 1px !important;
}
.metro-add-item .rw-list {
  font-size: 10px !important;
}
.metro-add-item {
  th {
    white-space: normal;
    font-size: 10px !important;
    text-align: center;
    padding: 3px 5px !important;
  }
  td {
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    padding: 5px 2px !important;
    font-size: 11px;
    .add-btn {
      font-size: 10px;
      padding: 2px 5px;
      border-radius: 4px;
    }
  }
  .metro-item-drop {
    text-align: left !important;
    font-size: 10px !important;

    min-width: 85px !important;
    .rw-popup-container {
      width: 85px !important;
    }
    .rw-list-option {
      font-size: 10px !important;
    }
  }
  .metro-type-drop {
    text-align: left !important;
    font-size: 10px !important;

    min-width: 70px !important;
    .rw-popup-container {
      width: 70px !important;
    }
    .rw-list-option {
      font-size: 10px !important;
    }
  }
  input {
    height: 25px !important;
    font-size: 11px;
    border-radius: 4px;
    padding: 5px 2px !important;
  }
}

.soldTo {
  label {
    font-size: 13px !important;
  }
  .sold-drop {
    height: 30px !important;
    font-size: 13px !important;
  }
  input {
    font-size: 13px !important;
    height: 30px !important;
    border-radius: 4px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.print-go-back {
  font-size: 12px;
  padding: 8px 10px;
}

.mh-300 {
  min-height: 300px;
}
.notice-table {
  td {
    vertical-align: middle !important;
    padding: 10px !important;
    font-size: 30px;
    // border: 1px solid #808080;
    button {
      font-size: 12px;
      padding: 5px 10px;
    }
    border-bottom: 3px solid #ece6e6;
  }
  // tr:hover{
  // background-color:  green!important;
  // }
}

//----------------------Transaction-table-in modal---------------------------------------
.transaction-table {
  td {
    vertical-align: middle !important;
    padding: 10px !important;
    font-size: 15px;
    border: 1px solid #ccc;

    // border: 1px solid #808080;
    input {
      width: 50% !important;
      height: 30px !important;
    }
  }
  .comment {
    input {
      width: 100% !important;
      height: 30px !important;
    }
  }
  .from-drop {
      height: 30px !important;
      width: 150px !important;
  }
}

//___________View items search bar_______________
.item-search {
  input[type="text"],
  input[type="number"] {
    height: 35px;
    padding: 6px;
    font-size: 12px;
  }

  .item-search-drop {
    font-size: 11px !important;
    width: 90px !important;
    .rw-popup-container {
      width: 90px !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .rw-select > * {
    width: 1em;
  }
}

//___________Daily section css_______________
.daily-table {
  td {
    padding: 8px 6px !important;
  }
  th {
    padding: 8px 6px !important;
  }
}

.chart-option-row {
  .option-drop {
    text-align: left !important;
    font-size: 12px !important;
    .rw-list-option {
      font-size: 12px !important;
    }
  }
}

//______________Advance Search_______________
.query-result {
  thead th {
    text-transform: none !important;
  }
  td {
    padding: 3px 2px !important;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  th {
    padding: 5px 15px !important;
    font-size: 11px !important;
  }
}

//-------------Checks----------------
.checks-main-content {
  .filter-By {
    text-align: left !important;
    font-size: 12px !important;
    width: 115px !important;
    padding: 1px !important;
  }
  .check-table-labels {
    p {
      margin: 0 !important;
      font-size: 14px !important;
    }
  }
  .rw-btn-select {
    padding: 0 !important;
  }
  input[type="checkbox"] {
    height: auto !important;
  }
  input[type="date"] {
    min-width: 125px !important;
    height: 28px !important;
    padding: 2px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    width: 15px;
    height: 15px;
    padding: 0px;
  }
}

.taxes-main-content {
  input[type="date"] {
    min-width: 125px !important;
    height: 28px !important;
    padding: 2px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    width: 15px;
    height: 15px;
    padding: 0px;
  }
}



.year-drop{
  .rw-list {
    max-height: 300px !important;
    font-size: 12px !important;
  }
  
}

.h-300{
  min-height: 400px!important;
}

.metal-page-content .Collapsible__trigger {
  text-align: center;
}

.metal-page-content .Collapsible {
  margin-bottom: 5px;
}

.signature-pad-box canvas {
  border: 2.5px solid black;
}

.back-gray {
  background-color: #efefef !important;
}

.signature-pad-box {
  max-width: 500px;
  margin: auto;
}
.main-content.profit-main-content.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.main-content.profit-main-content.container-fluid .card {
  border-radius: 0px;
}
.profit-main-content th {
  font-size: 15px !important;
}
.daily-main-content table th,
.daily-main-content table td {
  font-size: 14px !important;
}
.ticket-table input,
.ticket-table .rw-widget-input {
  border: 1px solid rgb(118, 118, 118) !important;
  box-shadow: none;
}
.ticket-table .input-profit-focus{
  border: 2px inset #5c5a5a !important;
}
.ticket-table input:focus,
.ticket-table .rw-widget-input:focus {
  border: 2px solid #5c5a5a !important;
  box-shadow: none;
}
.ticket-date-table td {
  vertical-align: top;
  padding: 8px !important;
  font-size: 14px;
  text-align: left !important;
}
.ticket-date-table {
  min-height: 30px !important;
}
.item-column .rw-list{
  max-height: 200px !important;
}
@media print {
  body {
    zoom: 80%;
    transform: scale(.7);
  }
}
.customer-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
iframe:last-child {
  display: none;
}
.customer-invoice-edit-table td {
  padding: 8px 20px 8px 10px !important;
  vertical-align: middle !important;
}
.create-invoice-table td {
  vertical-align: middle !important;
}
.react-viewer-navbar {
  display: none;
}
.striped-modal .modal-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.striped-modal .modal-body .form-group {
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: 700;
  color: #e82c2c;
  text-align: center;
}
.coin-invoice-table tfoot td {
  font-size: 16px !important;
}
.react-datepicker__input-container input{
  padding: 5px !important;
  border: 1px solid #08c !important;
  border-radius: 5px !important;
}
.table .thead-light th.empty-header {
  border-right: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
}
.table td.empty-footer {
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
}
.modal-custom-header .modal-title {
  font-size: 18px;
}
.modal-custom-header .close > span:not(.sr-only) {
  color: red;
  font-weight: 800;
  font-size: 1.6rem;
  position: relative;
  top: -10px;
  right: -10px;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.col-validity i {
  font-size: 26px;
}
.column-not-used {
  background-color: #fae5be;
}
.manage-invoice-table .rw-dropdown-list-input {
  padding-left: 2px !important;
}
.manage-invoice-table .rw-btn-select {
  width: 1em !important;
}
.manage-invoice-table .input-td input {
  padding: 2px 0.25rem !important;
}
.manage-invoice-table td {
  vertical-align: middle !important;
}